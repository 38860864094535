
.interactive-manage {
    min-height: calc(100vh - 100px);
}
.help-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        display: block;
    }
    .search-input {
        width: 320px;
        ::v-deep .el-input__inner {
            border-radius: 18px;
        }
    }
}
.help-content {
    display: flex;
    min-height: calc(100vh - 192px);
    margin-top: 17px;
    .help-class {
        width: 208px;
        min-height: 440px;
        background-color: #fff;
        border-radius: 10px;
        margin-right: 17px;
        .help-class-ul {
            color: #5F5C74;
            .super {
                display: flex;
                justify-content: space-between;
                height: 48px;
                line-height: 48px;
                cursor: pointer;
                .text {
                    padding-left: 20px;
                }
                i {
                    width: 48px;
                    padding-right: 10px;
                    text-align: center;
                }
                i.current_i {
                    transform: rotate(90deg);
                }
            }
        }
        .help-class-item {
            height: 48px;
            line-height: 48px;
            color: #5F5C74;
            cursor: pointer;
            .text {
                padding-left: 39px;
            }
            &:hover {
                background-color: #EDF7E1;
            }
        }
        .current {
            background-color: #EDF7E1;
            &:before {
                content: '';
                position: absolute;
                width: 3px;
                height: 48px;
                background-color: #9ACC5B;
            }
        }
    }
    .help-list-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 1%;
        background-color: #fff;
        border-radius: 10px;
        padding: 22px 40px;
        box-sizing: border-box;
        .class-text {
            padding-bottom: 20px;
            border-bottom: 1px solid #EEEEEE;
            color: #5F5C74;
            font-size: 16px;
        }
        .help-list {
            height: calc(100% - 103px);
            .help-list-item {
                &:last-child {
                    margin-bottom: 10px;
                }
                height: 56px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #EEEEEE;
                cursor: pointer;
                .article-title {
                    color: #3A3567;
                    font-size: 16px;
                }
                i {
                    color: #7D7D7D;
                }
                &:hover {
                    .article-title {
                        color: #564CED;
                    }
                    i {
                        color: #564CED;
                    }
                }
            }
        }
        .help-btn {
            width: 100px;
            background-color: #564CED;
            border-color: #564CED;
            margin: 22px auto 0;
            &:hover {
                background-color: #1b2dbf;
                border-color: #1b2dbf;
            }
        }
    }
}
.no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #5F5C74;
}
.article-dialog {
    ::v-deep .el-dialog {
        border-radius: 10px;
        ::v-deep .el-dialog__header {
            background-color: #F1F0FE;
            border-radius: 10px 10px 0 0;
        }
        ::v-deep .el-dialog__body {
            height: 500px;
            padding: 20px;
            .article-content {
                height: 100%;
                overflow: auto;
                p {
                    margin: 0 10px 10px 10px;
                    overflow: auto;
                }
                .video-show {
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    video {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
